import axios from "axios"

class Api {
  constructor() {
    this.url = process.env.REACT_APP_API_URL;
  }

  async generate(text, sourceVoice, targetVoice, useVoiceFixer) {

    const bodyData = 
    {
  		"input_text": text,
  		"source_name": sourceVoice,
  		"target_name": targetVoice,
      "apply_enhancement": useVoiceFixer
    }

    const res = await axios.post(this.url+"synthesize", bodyData)
    
    return res
  }
}

export default Api
