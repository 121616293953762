import React, { useState } from "react"

import DisplayVoice from "./DisplayVoice"
import Hint from "./Hint"

import lunaAudio from "./VoiceExample/luna.wav"
import sofiaAudio from "./VoiceExample/sofia.wav"
import danielAudio from "./VoiceExample/daniel.wav"
import andrewAudio from "./VoiceExample/andrew.wav"

import "./TargetVoice.css"


export default function TargetVoice({ targetVoice, onChangeTargetVoice, gender }) {
	return(
		<div className="TargetVoice">
			<div className="titelField">
				Target voice
				<Hint description="Target voice is the voice the system uses for cloning. This voice will be used to revoice the information spoken by the Source voice."/>
			</div>

			<DisplayVoice 
				voiceName="Luna"
				gender="female"
				disabled={gender != "female"}
				audioExample={lunaAudio}
				selectVoice={targetVoice}
				onValue={event => onChangeTargetVoice(event)}
			/>
			<DisplayVoice 
				voiceName="Sofia"
				gender="female"
				disabled={gender != "female"}
				audioExample={sofiaAudio}
				selectVoice={targetVoice}
				onValue={event => onChangeTargetVoice(event)}
			/>
			<DisplayVoice 
				voiceName="Daniel"
				gender="male"
				disabled={gender != "male"}
				audioExample={danielAudio}
				selectVoice={targetVoice}
				onValue={event => onChangeTargetVoice(event)}
			/>
			<DisplayVoice 
				voiceName="Andrew"
				gender="male"
				disabled={gender != "male"}
				audioExample={andrewAudio}
				selectVoice={targetVoice}
				onValue={event => onChangeTargetVoice(event)}
			/>
		</div>
	)
}
