import React from "react"

import { Radio } from '@mui/material'

import PlayAudio from "./PlayAudio"

import "./DisplayVoice.css"


export default function DisplayVoice({voiceName, gender, disabled=false, audioExample, selectVoice, onValue}) {
	const handleChange = (event) => {
		onValue(event.target.value);
	};

	return(
		<div className="DisplayVoice">
			<Radio
				checked={(selectVoice === voiceName.toLowerCase()) && !disabled}
				disabled={disabled}
			  onChange={handleChange}
			  value={voiceName.toLowerCase()}
			/>

			{`${voiceName} (${gender})`}

			<div className="playButton">
				<PlayAudio sound={audioExample} />
			</div>
		</div>
	)
}
