import React from "react"

import PlayAudio from "./PlayAudio"

import emmaAudio from "./VoiceExample/emma.wav"

import "./Result.css"


export default function Result({ sourceVoiceAudio, targetVoiceAudio }) {
	return(
		<div className="Result">
			<div className="generatedAudio">
				<div className="title">
					Result
				</div>
				<div className="resultItem">
					<div className="fieldName">
						Source voice
					</div>
					<PlayAudio sound={sourceVoiceAudio} displayProgress={true} />
				</div>
				<div className="resultItem">
					Target voice
					<PlayAudio sound={targetVoiceAudio} displayProgress={true} />
				</div>
			</div>
		</div>
	)
}
