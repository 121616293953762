import React, { useState, useEffect } from "react"

import DisplayVoice from "./DisplayVoice"
import Hint from "./Hint"

import emmaAudio from "./VoiceExample/emma.wav"
import jamesAudio from "./VoiceExample/james.wav"

import "./SourceVoice.css"


export default function SourceVoice({ sourceVoice, onChangeSourceVoice, onGender,}) {
	useEffect(() => {
  	if (sourceVoice == "emma") {
  		onGender("female")
  	} else if (sourceVoice == "james") {
  		onGender("male")
  	}
  }, [sourceVoice])

	return(
		<div className="SourceVoice">
			<div className="titelField">
				Source voice
				<Hint description="Source voice is the voice that will be used to synthesize the text from the Input text block. After that, the audio sample with the text synthesized by this voice will be sent to the input of the system for revoicing using Target voice."/>
			</div>

			<DisplayVoice 
				voiceName="Emma"
				gender="female"
				audioExample={emmaAudio}
				selectVoice={sourceVoice}
				onValue={event => onChangeSourceVoice(event)}
			/>

			<DisplayVoice 
				voiceName="James"
				gender="male"
				audioExample={jamesAudio}
				selectVoice={sourceVoice}
				onValue={event => onChangeSourceVoice(event)}
			/>
		</div>
	)
}
