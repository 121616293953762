import React, {useState} from "react"

import { Checkbox } from '@mui/material';

import InputText from "./InputText"
import SourceVoice from "./SourceVoice"
import TargetVoice from "./TargetVoice"
import Hint from "./Hint"

import "./InputData.css"

export default function InputData({ 
	inputText,
	onInputText, 
	sourceVoice, 
	onSourceVoice, 
	targetVoice, 
	onTargetVoice,  
	useVoiceFixer, 
	onUseVoiceFixer
}) {
	const [gender, setGender] = useState("female")

	return(
		<div className="InputData">
			<InputText
				inputText={inputText} 
				onChangeInputText={ event => onInputText(event) }
			/>

			<div className="sourceVoiceColumn">
				<SourceVoice 
					sourceVoice={sourceVoice} 
					onChangeSourceVoice={ event => onSourceVoice(event) } 
					onGender={ event => setGender(event) } 
				/>

				<div className="voiceFixerCheckBox">
					<Checkbox
			      checked={useVoiceFixer}
			      onChange={ event => onUseVoiceFixer(event.target.checked) }
			    />
			    Apply voice enhancement
					<Hint description="It is used to improve voiceover. It is recommended to use only if you are not satisfied with the quality of voice cloning."/>
				</div>
			</div>

			<TargetVoice 
				targetVoice={targetVoice}
				onChangeTargetVoice={ event => onTargetVoice(event) }
				gender={gender} 
			/>
		</div>
	)
}
