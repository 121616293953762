import React, { useState, useEffect } from "react";

import { IconButton, Slider, Box } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';

import "./PlayAudio.css"


export default function PlayAudio({ sound, displayProgress=false }) {
  const [audio] = useState(new Audio())
  const [currentTime, setCurrentTime] = useState(0);
  const [currentProgres, setCurrentProgres] = useState(0);
  const [play, setPlay] = useState(false);

  useEffect(() => {
    audio.addEventListener('timeupdate', (e) => {
      setCurrentProgres((e.srcElement.currentTime / audio.duration) * 100);
    });

    audio.addEventListener('ended', (e) => {
      setCurrentProgres(0);
      setPlay(false);
    });
  }, [])

  useEffect(() => {
    audio.src = sound
  }, [sound])

  useEffect(() => {
    if(play){
      audio.play();
    } else {
      audio.pause();
    }
  }, [play]);

  function handleCurrentTime(e) {
    let time = e.target.value * audio.duration / 100;
    setCurrentProgres(e.target.value);
    audio.currentTime = time;
  }

  const playSound = () => {
    setPlay(true);
  }

  const stopSound = () => {
    setPlay(false);
  }

  return(
    <div className="PlayAudio">
        {!play && <IconButton onClick={playSound} sx={{marginLeft: "-27px"}}>
          <PlayArrowIcon color="primary" fontSize="large"/>
        </IconButton>}
        {play && <IconButton onClick={stopSound} sx={{marginLeft: "-27px"}}>
          <StopIcon color="primary" fontSize="large"/>
        </IconButton>}
      {
        displayProgress && 
        <div className="progressBar">
          <Slider
            defaultValue={0}
            value={currentProgres}
            onChange={handleCurrentTime}
          />
        </div>
      }
    </div>
  )
}
