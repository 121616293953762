import React from "react"

import "./InputText.css"


export default function InputText({ inputText, onChangeInputText }) {
	return(
		<div className="InputText">
			<div className="titelField">
				Input text
			</div>
			<textarea
				value={inputText}
				className="inputTextField"
				maxLength="512"
				onChange={event => onChangeInputText(event.target.value)}
			/>
		</div>
	)
}
