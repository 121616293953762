import React from "react";

import { Tooltip } from '@mui/material';

import "./Hint.css"


export default function InfoHint({ description }) {
  return (
    <Tooltip describeChild title={description}>
      <div className="Hint">?</div>
    </Tooltip>
  );
}