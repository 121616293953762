import React, { useState } from "react"

import { Button, CircularProgress } from '@mui/material';

import InputData from "./components/InputData"
import Result from "./components/Result"

import Api from "./components/api"

import './App.css';


const progressStyle = {
  "& .MuiCircularProgress-svg": {
    "color": "#FFFFFF"
  }
}

function b64toBlob(b64Data, contentType="", sliceSize=512) {
  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  return new File(byteArrays, "pot", { type: contentType });
}

function App() {
  const [inputText, setInputText] = useState("")
  const [sourceVoice, setSourceVoice] = useState("emma")
  const [targetVoice, setTargetVoice] = useState("luna")
  const [useVoiceFixer, setUseVoiceFixer] = useState(false)

  const [isGenerated, setIsGenerated] = useState(false)

  const [sourceVoiceAudio, setSourceVoiceAudio] = useState()
  const [targetVoiceAudio, setTargetVoiceAudio] = useState()

  const [isLoading, setIsLoading] = useState(false)
  const [isError, setIsError] = useState(false)

  const handlClick = () => {
    setIsLoading(true)
    setIsError(false)
    setIsGenerated(false)
    
    const api = new Api()
    api.generate(inputText, sourceVoice, targetVoice, useVoiceFixer).then(res => {
      const sourceVoiceAudioFile = b64toBlob(res.data.voiced_text, "audio/wav")
      const sourceVoiceAudioFileUrl = URL.createObjectURL(sourceVoiceAudioFile)

      const targetVoiceAudioFile = b64toBlob(res.data.cloned_voice, "audio/wav")
      const targetVoiceAudioFileUrl = URL.createObjectURL(targetVoiceAudioFile)

      setSourceVoiceAudio(sourceVoiceAudioFileUrl)
      setTargetVoiceAudio(targetVoiceAudioFileUrl)

      setIsGenerated(true)
      setIsLoading(false)
    }).catch(ERR => {
      setIsError(true)
      setIsLoading(false)
      setIsGenerated(false)
    }).finally(() => {
      setIsLoading(false)
    })
  }

  return (
    <div className="App">
      <InputData 
        inputText={inputText}
        onInputText={ event => setInputText(event) }
        sourceVoice={sourceVoice}
        onSourceVoice={ event => setSourceVoice(event) }
        targetVoice={targetVoice}
        onTargetVoice={ event => setTargetVoice(event) }
        useVoiceFixer={useVoiceFixer}
        onUseVoiceFixer={ event => setUseVoiceFixer(event) }
      />

      <div className="generateButtonContainer">
        <Button variant="contained" onClick={handlClick} disabled={!inputText.trim()}>
          {isLoading ? <CircularProgress sx={progressStyle} size={24}/> : "Generate"}
        </Button>
        {isError && <div className="errorMessage">Unexpected error has ocurred</div>}
      </div>
      
      {
        isGenerated && 
        <Result 
          sourceVoiceAudio={sourceVoiceAudio}
          targetVoiceAudio={targetVoiceAudio}
        />
      }
    </div>
  );
}

export default App;
